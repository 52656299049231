import { render, staticRenderFns } from "./order_list.vue?vue&type=template&id=606d43ba&scoped=true&"
import script from "./order_list.vue?vue&type=script&lang=js&"
export * from "./order_list.vue?vue&type=script&lang=js&"
import style0 from "./order_list.vue?vue&type=style&index=0&id=606d43ba&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "606d43ba",
  null
  
)

export default component.exports