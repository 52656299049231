<template>
  <ContainerList
    ref="container"
    :page-size="filterData.pageSize"
    :page-current.sync="filterData.pageNo"
    :page-count="totalNum"
    @page-change="changePageNo"
    @page-size-change="changePageSize"
  >
    <div slot="header">
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>分账方</i>
            <Input
              v-model="filterData.keyword"
              suffix="ios-search"
              size="large"
              maxlength="128"
              placeholder="分账方名称/编号"
            />
          </div>
          <!-- <div class="item">
            <i>所属商户</i>
            <Select
              v-model="filterData.mchId"
              size="large"
              placeholder="选择所属商户"
            >
              <Option
                v-for="item in mchIdOptions"
                :value="item.value"
                :key="item.value"
              >{{ item.label }}</Option>
            </Select>
          </div> -->
          <div class="item">
            <i>订单状态</i>
            <Select
              v-model="filterData.orderStatus"
              size="large"
              placeholder="选择订单状态"
            >
              <Option
                v-for="item in orderStatusOptions"
                :value="item.value"
                :key="item.value"
              >{{ item.label }}</Option>
            </Select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>时间类型</i>
            <Select
              v-model="filterData.timeType"
              size="large"
              placeholder="创建时间"
              style="width: 130px;"
            >
              <Option
                v-for="item in timeTypeOptions"
                :value="item.value"
                :key="item.value"
              >{{ item.label }}</Option>
            </Select>
            <span style="padding: 0 10px"></span>
            <DatePicker
              v-model="filterData.startTime"
              type="datetime"
              :clearable="false"
              :editable="false"
              :options="startDatePicker"
              placeholder="选择时间"
              style=" width:calc((100% - 130px - 70px - 44.67px - 10px) / 2) !important;"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </DatePicker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <DatePicker
              v-model="filterData.endTime"
              type="datetime"
              :clearable="false"
              :editable="false"
              :options="endDatePicker"
              placeholder="选择时间"
              style=" width:calc((100% - 130px - 70px - 44.67px - 10px) / 2) !important;"
              format="yyyy-MM-dd HH:mm:ss"
              @on-change="endTimeChange"
            >
            </DatePicker>
          </div>
          <div class="itembtn">
            <Button
              class="searchBt"
              @click="getDataByFilter()"
              style="margin-left:20px;"
            >查询</Button>
            <button
              v-auth="'BASIC:BALANCE:SETTLE/EXPORT'"
              class="outputBt"
              @click="outputShow"
              style="margin-left:20px;"
            >导出</button>
          </div>
        </div>
      </div>
    </div>
    <div class="jiaoyi">
      <div class="jiaoyi_box">
        <p><i>结算笔数（笔）</i></p>
        <p>
          <b>{{ tradeNum }}</b>
        </p>
      </div>
      <div class="jiaoyi_box">
        <p><i>结算金额（元）</i></p>
        <p>
          <b>{{ (settleAmount / 100) | formatMoney }}</b>
        </p>
      </div>
      <div class="jiaoyi_box">
        <p><i>分账方手续费（元）</i></p>
        <p>
          <b>{{ (allocMchSettleFee / 100) | formatMoney }}</b>
        </p>
      </div>
      <div class="jiaoyi_box">
        <p><i>实际到账金额（元）</i></p>
        <p>
          <b>{{ (remitAmount / 100) | formatMoney }}</b>
        </p>
      </div>
    </div>
    <Table
      row-key
      min-width="1000px"
      class="table"
      :columns="tableColumns"
      :data="tableData"
      :loading="isLoading"
    >
      <template slot="settleAmountAction" slot-scope="{ row }">
        <p>{{(row.settleAmount / 100) | formatMoney}}</p>
        <p>{{(row.allocMchSettleFee / 100) | formatMoney}}</p>
      </template>
      <template slot="remitAmount" slot-scope="{ row }">
        <p>{{ (row.remitAmount / 100) | formatMoney }}</p>
      </template>
      <template slot="orderStatus" slot-scope="{ row }">
        <p>{{ orderStatus[row.orderStatus] }}</p>
      </template>
      <template
        slot="action"
        slot-scope="{ row , index}"
      >
        <div class="actionBtn">
          <button
            v-auth="'BASIC:BALANCE:SETTLE/DETAIL'"
            class="lianjie"
            @click="toGoDetail(row)"
          >详情</button>
          <button
            v-auth="'BASIC:BALANCE:SETTLE/SYNC'"
            class="lianjie"
            @click="syncBtn(row,index)"
          >同步</button>
        </div>
      </template>
    </Table>
    <!-- 导出弹窗 -->
    <Modal
      v-model="outPayShow"
      title="导出结算记录"
      closable
      @on-cancel="outPayShow = false"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_main">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <Select
            v-model="selectTimeType"
            size="large"
            placeholder="选择交易日期"
            @on-change="timeChange"
          >
            <Option
              v-for="item in selectTimeTypeOptions"
              :value="item.value"
              :key="item.value"
            >{{ item.label }}</Option>
          </Select>
        </li>
        <li class="ul_left">
          <div
            class="overAccount_ul_title"
            v-show="selectTimeType == -1"
          >
            <i>*</i>开始日期
          </div>
          <DatePicker
            :options="startOption"
            v-model="outputForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </DatePicker>
        </li>
        <li class="ul-right">
          <div
            class="overAccount_ul_title"
            v-show="selectTimeType == -1"
          >
            <i>*</i>结束日期
          </div>
          <DatePicker
            :options="endOption"
            v-model="outputForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </DatePicker>
        </li>
        <li
          class="ul_main"
          style="clear: both;"
        ></li>
      </ul>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <Button
          class="exitBt"
          @click="outPayShow = false"
        >取 消</Button>
        <Button
          class="addBt"
          @click="output"
        >确 定</Button>
      </span>
    </Modal>
  </ContainerList>
</template>
<script>
import { formatDate, FormatTime } from "@/utils/common.js";
import {
  getSettleorders,
  setSettleordersNotify,setSettleordersSync,
  exportSettleorders
} from "@/api/basic/balance.js";
import { mapState, mapMutations } from "vuex";
import ContainerList from '@/components/ContainerList/index'
import tableMixin from '@/mixins/tableMixin'
export default {
  components: { ContainerList },
  mixins: [tableMixin],
  data() {
    let that = this;
    return {
      orderStatusOptions: [
        { label: "全部", value: -1, },
        { label: "处理中", value: "PROCESSING", },
        { label: "结算成功", value: "SUCCESS", },
        { label: "结算失败", value: "FAIL", },
      ],
      timeTypeOptions: [
        { label: "创建时间", value: "CREATE", },
        { label: "交易时间", value: "TRADE", },
      ],
      orderNoTypeOptions: [
        { label: "结算订单号", value: "mchSettleOrderNo", },
        { label: "结算流水号", value: "plfSettleOrderNo", },
        { label: "三方结算订单号", value: "upsSettleOrderNo", },
      ],
      accountTypeOptions: [
        { label: "结算账户", value: "CARD", },
        { label: "结算账户名", value: "NAME", },
      ],
      selectTimeTypeOptions: [
        { label: "今天", value: "1", },
        { label: "昨天", value: "2", },
        { label: "近7天", value: "7", },
        { label: "其它", value: "-1", },
      ],
      mchIdOptions: [],
      filterData: {
        startTime: formatDate(+new Date(), "yyyy/MM/dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy/MM/dd 23:59:59"),
        pageNo: 1,
        pageSize: 10,
        timeType: "CREATE",
        // mchId: "",
        keyword: "",
        orderStatus: -1,
      },
      mchDrop: [],
      totalNum: 0,
      tradeNum: 0,
      settleAmount: 0,
      allocMchSettleFee: 0,
      remitAmount: 0,
      orderStatus: {
        PROCESSING: "处理中",
        FAIL: "结算失败",
        SUCCESS: "结算成功",
      },
      orderNoType: {
        mchSettleOrderNo: "结算订单号",
        plfSettleOrderNo: "结算流水号",
        upsSettleOrderNo: "三方结算订单号",
        CARD: "结算账户",
        NAME: "结算账户名",
      },
      tableData: [],
      tableColumns: [
        {
          title: '创建时间',
          key: 'createTime',
          align: 'center',
          minWidth: 170,
          renderHeader: (h) => {
            return h('div', [
              h('p', '创建时间'),
              h('p', '交易时间'),
            ])
          },
          render(h, params) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', {
                domProps: {
                  innerHTML: `${params.row.createTime}`
                }
              }),
              h('p', {
                domProps: {
                  innerHTML: `${params.row.tradeTime}`
                }
              })
            ])
          }
        },
        {
          title: '分账方名称',
          key: '',
          align: 'center',
          minWidth: 100,
          renderHeader: (h) => {
            return h('div', [
              h('p', '分账方名称'),
              h('p', '分账方编号'),
            ])
          },
          render(h, params) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', {
                domProps: {
                  innerHTML: `${params.row.allocMchName}`
                }
              }),
              h('p', {
                domProps: {
                  innerHTML: `${params.row.allocMchId}`
                }
              })
            ])
          }
        },
        // {
        //   title: '所属商户',
        //   key: 'mchName',
        //   align: 'center',
        //   minWidth: 70,
        // },
        {
          title: '结算订单号',
          key: '',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '结算订单号'),
              h('p', '结算流水号'),
            ])
          },
          render(h, params) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', {
                domProps: {
                  innerHTML: `${params.row.mchSettleOrderNo}`
                }
              }),
              h('p', {
                domProps: {
                  innerHTML: `${params.row.plfSettleOrderNo}`
                }
              })
            ])
          }
        },
        {
          title: '结算金额(元',
          key: 'settleAmount',
          align: 'center',
          minWidth: 120,
          renderHeader: (h) => {
            return h('div', [
              h('p', '结算金额(元'),
              h('p', '分账方手续费(元)'),
            ])
          },
          slot:'settleAmountAction',
        },
        {
          title: '实际到账金额(元)',
          key: 'remitAmount',
          align: 'center',
          minWidth: 120,
          slot:"remitAmount",
        },
        {
          title: '结算账户',
          key: '',
          align: 'center',
          minWidth: 210,
          renderHeader: (h) => {
            return h('div', [
              h('p', '结算账户'),
              h('p', '结算账户名'),
            ])
          },
          render(h, params) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', {
                domProps: {
                  innerHTML: `${params.row.accountNo}`
                }
              }),
              h('p', {
                domProps: {
                  innerHTML: `${params.row.accountName}`
                }
              })
            ])
          }
        }, {
          title: '订单状态',
          key: 'orderStatus',
          align: 'center',
          minWidth: 70,
          slot:'orderStatus',
        },
        { title: '操作', key: 'action', align: 'center', slot: 'action', minWidth: 90 }
      ],
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
            new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
            new Date(
              +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
            )
          );
        },
      },
      startOption: {
        disabledDate(date) {
          return (
            date.getTime() >
            new Date(that.outputForm.endDate) + 24 * 60 * 60 * 1000 ||
            date.getTime() <
            new Date(
              +new Date(that.outputForm.endDate) - 31 * 24 * 60 * 60 * 1000
            )
          );
        },
      },
      outPayShow: false, //导出展示
      outputForm: {}, //导出信息
      selectTimeType: "", //导出时间类型
      outPayTime: "",
      startDatePicker: this.startDate(),
      endDatePicker: this.endData(),
      tableApi: getSettleorders,
      tableKey: 'orders', // 后端返回的列表字段名
      isFilterKey: true, // 是否开启参数转换
      emptyValue: -1,
    };
  },
  computed: {
    ...mapState({
      refundSgDetail: "trading_refund_sg",
    }),
  },
  created() {
    if (this.refundSgDetail && this.refundSgDetail.query) {
      this.filterData = this.refundSgDetail.query;
      // this.filterData.orderStatus = this.filterData.orderStatus == '' ? '-1' : this.filterData.orderStatus;
    }
    this.getDataByFilter()
  },
  methods: {
    ...mapMutations({
      setRefundSg: "trading_setRefund_sg",
    }),
    // 请求前钩子
    beforeRequest(params) {
      params.keyword = params.keyword.trim();
      params.startTime = FormatTime('yyyy-MM-dd hh:mm:ss', params.startTime);
      params.endTime = FormatTime('yyyy-MM-dd hh:mm:ss', params.endTime);
      // params.orderStatus = params.orderStatus == -1 ? "" : params.orderStatus;
      return params
    },
    afterResponse(list, data) {
      this.totalNum = data.totalNum || 0;
      this.totalNum = data.totalNum;
      this.tradeNum = data.tradeNum;
      this.settleAmount = data.settleAmount;
      this.allocMchSettleFee = data.allocMchSettleFee;
      this.remitAmount = data.remitAmount;
      return list
    },
    startDate() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.endTime
            ? !(
              new Date(vm.filterData.endTime).getTime() >= time.getTime() &&
              time.getTime() >
              new Date(vm.filterData.endTime).getTime() -
              31 * 24 * 60 * 60 * 1000
            )
            : false;
        },
      };
    },
    endData() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.startTime
            ? !(
              new Date(vm.filterData.startTime).getTime() <= time.getTime() &&
              time.getTime() <
              new Date(vm.filterData.startTime).getTime() +
              31 * 24 * 60 * 60 * 1000
            )
            : true;
        },
      };
    },
    syncBtn(data,index){
      setSettleordersSync(data.settleOrderId).then((res) => {
        if (res.resultStatus) {
          this.tableData[index].orderStatus = res.resultData.orderStatus;
          this.$Message.success("同步请求成功");
          // this.search();
        }
      });
    },
    // 通知
    setNotify(id) {
      setSettleordersNotify(id).then((res) => {
        if (res) {
          this.$Message.success("通知请求成功");
        }
      });
    },
    // 详情
    toGoDetail(value) {
      this.$router.push({
        name: "settle_detail",
        query: {
          id: value.settleOrderId,
        },
      });
    },
    //创建时间改变时
    endTimeChange(time, type) {
      if (type == 'date' && time.slice(-8) === '00:00:00') {
        this.filterData.endTime = time.slice(0, -8) + '23:59:59'
      }
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$Message.error("导出时间段必选");
        return;
      }
      if (this.outputForm.startDate > this.outputForm.endDate) {
        return this.$Message.error("结束时间不能小于开始时间");
      }
      this.outputForm.startDate = formatDate(+new Date(this.outputForm.startDate), "yyyy-MM-dd")
      this.outputForm.endDate = formatDate(+new Date(this.outputForm.endDate), "yyyy-MM-dd")
      let data = this.outputForm;
      exportSettleorders(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "结算查询.csv");
          document.body.appendChild(link);
          link.click();
        }
        this.outPayShow = false;
      });
    },
  },
  beforeDestroy() {
    let data = {
      query: this.filterData,
      formData: this.refundSgDetail ? this.refundSgDetail.formData : null,
    };
    this.setRefundSg(data);
  },
};
</script>

<style lang="less" scoped>
@import "../../../../../assets/css/desc.css";
@import "../../../../../assets/css/detail.css";
.container {
  overflow: hidden;
  background: #fff;
}
.con_from .lineP .itemTime {
  width: 63.5%;
}
.table {
  min-height: 750px !important;
}
.jiaoyi {
  margin-bottom: 20px;
  padding: 0px 4px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border: 1px solid var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}
.overAccount_ul {
  padding: 0px 20px;
  box-sizing: border-box;
}
.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}
.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}
.ul_main {
  width: 100%;
  margin: 0 auto 7px;
}
.ul_left {
  float: left;
  width: 200px;
}
.ul-right {
  float: right;
  width: 200px;
}
</style>