<template>
  <ContainerList
    ref="container"
    :show-page="false"
  >
    <div slot="header">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <Select
              v-model="filterData.orderNoType"
              size="large"
              placeholder="选择订单状态"
              style="margin-right: 20px;"
            >
              <Option
                v-for="item in orderNoTypeOptions"
                :value="item.value"
                :key="item.value"
              >{{ item.label }}</Option>
            </Select>
            <Input
              v-model="filterData.orderNo"
              suffix="ios-search"
              size="large"
              maxlength="128"
              :placeholder="'搜索' + orderNoType[filterData.orderNoType]"
            />
          </div>
          <div class="item itemTwo">
            <Select
              v-model="filterData.accountType"
              size="large"
              placeholder="选择订单状态"
              style="margin-right: 20px;"
            >
              <Option
                v-for="item in accountTypeOptions"
                :value="item.value"
                :key="item.value"
              >{{ item.label }}</Option>
            </Select>
            <Input
              v-model="filterData.account"
              suffix="ios-search"
              size="large"
              maxlength="128"
              :placeholder="'搜索' + orderNoType[filterData.accountType]"
            />
          </div>
          <div class="itembtn itemTwobtn">
            <Button
              class="searchBt"
              @click="getDataByFilterBtn"
            >查询</Button>
          </div>
        </div>
      </div>
    </div>
    <Table
      row-key
      min-width="1000px"
      class="table"
      :columns="tableColumns"
      :data="orderData"
      :loading="isLoading"
    >
      <template slot="settleAmountAction" slot-scope="{ row }">
        <p>{{(row.settleAmount / 100) | formatMoney}}</p>
        <p>{{(row.allocMchSettleFee / 100) | formatMoney}}</p>
      </template>
      <template slot="remitAmount" slot-scope="{ row }">
        <p>{{ (row.remitAmount / 100) | formatMoney }}</p>
      </template>
      <template slot="orderStatus" slot-scope="{ row }">
        <p>{{ orderStatus[row.orderStatus] }}</p>
      </template>
      <template
        slot="action"
        slot-scope="{ row , index }"
      >
        <div class="actionBtn">
          <button
            v-auth="'BASIC:BALANCE:SETTLE/DETAIL'"
            class="lianjie"
            @click="toGoDetail(row)"
          >详情</button>
          <button
            v-auth="'BASIC:BALANCE:SETTLE/SYNC'"
            class="lianjie"
            @click="syncBtn(row,index)"
          >同步</button>
        </div>
      </template>
    </Table>
  </ContainerList>
</template>
<script>
import {
  getSettleordersSearch,setSettleordersSync,
  setSettleordersNotify,
} from "@/api/basic/balance.js";
import { mapState, mapMutations } from "vuex";
import ContainerList from '@/components/ContainerList/index'
import tableMixin from '@/mixins/tableMixin'
export default {
  components: { ContainerList },
  mixins: [tableMixin],
  data() {
    let that = this;
    return {
      orderStatusOptions: [
        { label: "全部", value: -1, },
        { label: "处理中", value: "PROCESSING", },
        { label: "结算成功", value: "SUCCESS", },
        { label: "结算失败", value: "FAIL", },
      ],
      timeTypeOptions: [
        { label: "创建时间", value: "CREATE", },
        { label: "交易时间", value: "TRADE", },
      ],
      orderNoTypeOptions: [
        { label: "结算订单号", value: "mchSettleOrderNo", },
        { label: "结算流水号", value: "plfSettleOrderNo", },
        { label: "三方结算订单号", value: "upsSettleOrderNo", },
      ],
      accountTypeOptions: [
        { label: "结算账户", value: "CARD", },
        { label: "结算账户名", value: "NAME", },
      ],
      orderNoType: {
        mchSettleOrderNo: "提现订单号",
        plfSettleOrderNo: "提现流水号",
        upsSettleOrderNo: "三方提现订单号",
        CARD: "提现账户",
        NAME: "提现账户名",
      },
      selectTimeTypeOptions: [
        { label: "今天", value: "1", },
        { label: "昨天", value: "2", },
        { label: "近7天", value: "7", },
        { label: "其它", value: "-1", },
      ],
      mchIdOptions: [],
      mchDrop: [],
      totalNum: 0,
      orderStatus: {
        PROCESSING: "处理中",
        FAIL: "结算失败",
        SUCCESS: "结算成功",
      },
      filterData: {
        orderNoType: "mchSettleOrderNo",
        orderNo: "",
        accountType: "CARD",
        account: "",
        pageNo: 1,
        pageSize: 10,
      },
      orderNoType: {
        mchSettleOrderNo: "结算订单号",
        plfSettleOrderNo: "结算流水号",
        upsSettleOrderNo: "三方结算订单号",
        CARD: "结算账户",
        NAME: "结算账户名",
      },
      orderData: [],
      tableColumns: [
        {
          title: '创建时间',
          key: 'createTime',
          align: 'center',
          minWidth: 70,
          renderHeader: (h) => {
            return h('div', [
              h('p', '创建时间'),
              h('p', '交易时间'),
            ])
          },
          render(h, params) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', {
                domProps: {
                  innerHTML: `${params.row.createTime}`
                }
              }),
              h('p', {
                domProps: {
                  innerHTML: `${params.row.tradeTime}`
                }
              })
            ])
          }
        },
        {
          title: '分账方名称',
          key: '',
          align: 'center',
          minWidth: 100,
          renderHeader: (h) => {
            return h('div', [
              h('p', '分账方名称'),
              h('p', '分账方编号'),
            ])
          },
          render(h, params) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', {
                domProps: {
                  innerHTML: `${params.row.allocMchName}`
                }
              }),
              h('p', {
                domProps: {
                  innerHTML: `${params.row.allocMchId}`
                }
              })
            ])
          }
        },
        // {
        //   title: '所属商户',
        //   key: 'mchName',
        //   align: 'center',
        //   minWidth: 70,
        // },
        {
          title: '结算订单号',
          key: '',
          align: 'center',
          minWidth: 130,
          renderHeader: (h) => {
            return h('div', [
              h('p', '结算订单号'),
              h('p', '结算流水号'),
            ])
          },
          render(h, params) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', {
                domProps: {
                  innerHTML: `${params.row.mchSettleOrderNo}`
                }
              }),
              h('p', {
                domProps: {
                  innerHTML: `${params.row.plfSettleOrderNo}`
                }
              })
            ])
          }
        },
        {
          title: '结算金额(元',
          key: 'settleAmount',
          align: 'center',
          minWidth: 120,
          renderHeader: (h) => {
            return h('div', [
              h('p', '结算金额(元'),
              h('p', '分账方手续费(元)'),
            ])
          },
          slot:'settleAmountAction',
        },
        {
          title: '实际到账金额(元)',
          key: 'remitAmount',
          align: 'center',
          minWidth: 120,
          slot:"remitAmount",
        },
        {
          title: '结算账户',
          key: '',
          align: 'center',
          minWidth: 70,
          renderHeader: (h) => {
            return h('div', [
              h('p', '结算账户'),
              h('p', '结算账户名'),
            ])
          },
          render(h, params) {
            return h('div', {
              style: {
                display: 'flex',
                flexDirection: 'column'
              }
            }, [
              h('p', {
                domProps: {
                  innerHTML: `${params.row.accountNo}`
                }
              }),
              h('p', {
                domProps: {
                  innerHTML: `${params.row.accountName}`
                }
              })
            ])
          }
        }, {
          title: '订单状态',
          key: 'orderStatus',
          align: 'center',
          minWidth: 70,
          slot:'orderStatus',
        },
        { title: '操作', key: 'action', align: 'center', slot: 'action', minWidth: 60 }
      ],
      tableApi: getSettleordersSearch,
    };
  },
  computed: {
    ...mapState({
      refundSgDetail: "trading_refund_sg",
    }),
  },
  created() {
    if (this.refundSgDetail && this.refundSgDetail.formData) {
      this.filterData = this.refundSgDetail.formData;
    }
    if (this.filterData.orderNo!=="" || this.filterData.account !== '') {
      this.getDataByFilter();
    }
  },
  methods: {
    ...mapMutations({
      setRefundSg: "trading_setRefund_sg",
    }),
    // 请求前钩子
    beforeRequest(params) {
      delete params.pageNo
      delete params.pageSize
      return params
    },
    afterResponse(list, data) {
      console.log(data)
      this.orderData = data;
      this.totalNum = data.totalNum || 0;
      return list
    },
    getDataByFilterBtn(){
      let params = {
        orderNoType: this.filterData.orderNoType,
        orderNo: this.filterData.orderNo.trim(),
        accountType: this.filterData.accountType,
        account: this.filterData.account.trim(),
      };
      if (!params.orderNo && !params.account) {
        this.$Message.error(
          "请输入" +
          this.orderNoType[this.filterData.orderNoType] +
          "或" +
          this.orderNoType[this.filterData.accountType]
        );
        return false;
      }
      this.getDataByFilter();
    },
    // 通知
    setNotify(id) {
      setSettleordersNotify(id).then((res) => {
        if (res) {
          this.$Message.success("通知请求成功");
        }
      });
    },
    syncBtn(data,index){
      setSettleordersSync(data.settleOrderId).then((res) => {
        if (res.resultStatus) {
          this.orderData[index].orderStatus = res.resultData.orderStatus;
          this.$Message.success("同步请求成功");
          // this.search();
        }
      });
    },
    // 详情
    toGoDetail(value) {
      this.$router.push({
        name: "settle_detail",
        query: {
          id: value.settleOrderId,
        },
      });
    },
  },
  beforeDestroy() {
    let data = {
      query: this.refundSgDetail ? this.refundSgDetail.query : null,
      formData: this.filterData,
    };
    this.setRefundSg(data);
  },
};
</script>

<style lang="less" scoped>
@import "../../../../../assets/css/desc.css";
@import "../../../../../assets/css/detail.css";
.container {
  overflow: hidden;
  background: #fff;
}
.con_from .lineP .itemTime {
  width: 63.5%;
}
.table {
  min-height: 750px !important;
}
</style>